<template>
    <v-autocomplete
        :multiple="multiple"
        :label="label"
        v-model="selected"
        :items="items"
        :item-text="item => item.text"
        return-object
        @change="comboBoxSelected"
        :clearable="required ? false : true"
        @click:clear="clearSelectedFilter()"
        :disabled="disabled"
    >
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        multiple: { type: Boolean, required: true },
        label: { type: String, required: true },
        items: { type: Array, required: true },
        clearAllFilters: { type: Boolean },
        required: { type: Boolean, required: true },
        disabled: { type: Boolean, required: true }
    },

    data () {
        return {
            selected: [],
            filters: 0
        }
    },

    methods: {
        comboBoxSelected(event) {
            if (event) {
                if (this.multiple === false) {
                    if (this.filters === 0) {
                        this.$emit('filtersCounter', 1)
                    } 
        
                    this.filters = event.id
                } else {
                    let activeFilters = event.length
        
                    if (activeFilters > this.filters) {
                        this.$emit('filtersCounter', 1)
                    } else {
                        this.$emit('filtersCounter', -1)
                    }
        
                    this.filters = activeFilters
                }

                this.$emit('setSelection', this.selected)
            }
        },

        clearSelectedFilter() {
            this.$emit('filtersCounter', -1)
            this.$emit('removeSelection')
            this.filters = 0
        }
    },

    watch: {
        clearAllFilters(val) {
            if (val === true) {
                this.selected = []
                this.filters = 0

                this.$emit('changeClearAllFiltersBackToFalse')
            }
        }
    }
}
</script>

<style>

</style>