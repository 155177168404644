<template>
    <v-checkbox
        :label="label"
        :value="filter.value"
        @change="checkboxSelected($event)"
    >

    </v-checkbox>
</template>

<script>
export default {
    props: {
        filter: { type: Object, required: true },
        multiple: { type: Boolean, required: true },
        label: { type: String, required: true },
        items: { type: Array, required: true },
        clearAllFilters: { type: Boolean },
        required: { type: Boolean, required: true },
        disabled: { type: Boolean, required: true }
    },

    data () {
        return {
            selected: [],
            filters: 0
        }
    },

    methods: {
        checkboxSelected(e) {
            this.$emit('setCheckboxValue', { column: this.filter.column, value: e })
        },

        clearSelectedFilter() {
            this.$emit('filtersCounter', -1)
            this.$emit('removeSelection')
            this.filters = 0
        }
    },

    watch: {
        clearAllFilters(val) {
            if (val === true) {
                this.selected = []
                this.filters = 0

                this.$emit('changeClearAllFiltersBackToFalse')
            }
        }
    }
}
</script>

<style>

</style>